
  import React from 'react';
import {colors} from '@workday/canvas-kit-react/tokens';
import {SystemIconCircle} from '@workday/canvas-kit-react/icon';
import {activityStreamIcon} from '@workday/canvas-system-icons-web';

const Example = () => (
  <>
    <SystemIconCircle icon={activityStreamIcon} />
    <SystemIconCircle icon={activityStreamIcon} background={colors.pomegranate500} />
  </>
);

export default Example;
  Example.__RAW__ = "import React from 'react';\nimport {colors} from '@workday/canvas-kit-react/tokens';\nimport {SystemIconCircle} from '@workday/canvas-kit-react/icon';\nimport {activityStreamIcon} from '@workday/canvas-system-icons-web';\n\nexport default () => (\n  <>\n    <SystemIconCircle icon={activityStreamIcon} />\n    <SystemIconCircle icon={activityStreamIcon} background={colors.pomegranate500} />\n  </>\n);\n";