
  import React from 'react';
import {colors} from '@workday/canvas-kit-react/tokens';
import {SystemIcon} from '@workday/canvas-kit-react/icon';
import {activityStreamIcon} from '@workday/canvas-system-icons-web';

const Example = () => (
  <>
    <SystemIcon icon={activityStreamIcon} />
    <SystemIcon icon={activityStreamIcon} color={colors.blueberry500} />
    <SystemIcon
      icon={activityStreamIcon}
      accent={colors.frenchVanilla100}
      fill={colors.blueberry500}
      background={colors.blueberry500}
    />
    <SystemIcon icon={activityStreamIcon} size={48} />
  </>
);

export default Example;
  Example.__RAW__ = "import React from 'react';\nimport {colors} from '@workday/canvas-kit-react/tokens';\nimport {SystemIcon} from '@workday/canvas-kit-react/icon';\nimport {activityStreamIcon} from '@workday/canvas-system-icons-web';\n\nexport default () => (\n  <>\n    <SystemIcon icon={activityStreamIcon} />\n    <SystemIcon icon={activityStreamIcon} color={colors.blueberry500} />\n    <SystemIcon\n      icon={activityStreamIcon}\n      accent={colors.frenchVanilla100}\n      fill={colors.blueberry500}\n      background={colors.blueberry500}\n    />\n    <SystemIcon icon={activityStreamIcon} size={48} />\n  </>\n);\n";