import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import { activityStreamIcon } from '@workday/canvas-system-icons-web';
import Example from './examples/SystemIcon.tsx';
import Example2 from './examples/SystemIconCircle.tsx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const ExampleCodeBlock = makeShortcode("ExampleCodeBlock");
const SymbolDoc = makeShortcode("SymbolDoc");
const InternalContent = makeShortcode("InternalContent");
const SearchableIconList = makeShortcode("SearchableIconList");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Guidance" mdxType="TabPanel">
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`System icons are designed to display essential characteristics that can communicate metaphors at a
glance. Some icons have specific meaning at Workday, while others are universally recognized to
display a specific function. In this section you will find guidance on the use of System icons.`}</p>
      <h2 {...{
        "id": "universal-icons"
      }}>{`Universal Icons`}</h2>
      <p>{`Some icons are universally understood, for example, common icons like ‘home’ or ‘print’ share the
same standardized meaning in many countries and cultures. An absence of this standard can impact our
shared understanding, and we can no longer rely on the same functionality when a universal icon is
encountered.`}</p>
      <p>{`While universal icons are rare, it is advised that these icons are used for their specific intended
purpose, below are some examples of universal icons encountered in the Canvas Design System.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8bdc883dad6ccfe9969d64c301e0085a/07a9c/system_icon_universal_icons.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "49.18918918918919%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of universally understood System Icons.",
              "title": "Example of universally understood System Icons.",
              "src": "/static/8bdc883dad6ccfe9969d64c301e0085a/50383/system_icon_universal_icons.png",
              "srcSet": ["/static/8bdc883dad6ccfe9969d64c301e0085a/1efb2/system_icon_universal_icons.png 370w", "/static/8bdc883dad6ccfe9969d64c301e0085a/50383/system_icon_universal_icons.png 740w", "/static/8bdc883dad6ccfe9969d64c301e0085a/07a9c/system_icon_universal_icons.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "directional-icons"
      }}>{`Directional Icons`}</h2>
      <p>{`Icons are commonly used to help us navigate from page to page, being consistent with the direction
that these icons will take us in is important when we expect to be taken in the direction we choose.
If a consistent pattern is not maintained, trust is diminished. The general rule of thumb is to
point in the direction you expect to be taken.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f2823f8f74881463c37ecef43cdec3ef/07a9c/system_icon_directional_icons.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "15.405405405405407%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of directional System Icons.",
              "title": "Example of directional System Icons.",
              "src": "/static/f2823f8f74881463c37ecef43cdec3ef/50383/system_icon_directional_icons.png",
              "srcSet": ["/static/f2823f8f74881463c37ecef43cdec3ef/1efb2/system_icon_directional_icons.png 370w", "/static/f2823f8f74881463c37ecef43cdec3ef/50383/system_icon_directional_icons.png 740w", "/static/f2823f8f74881463c37ecef43cdec3ef/07a9c/system_icon_directional_icons.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "bidirectionality"
      }}>{`Bidirectionality`}</h3>
      <p>{`Some icons should be mirrored for bidirectional support so the same interpertation of an icon is
correct for languages read in different directions. Navigation icons are most commonly mirrored,
icons that deptic text, movement or interactable elements should also do this. For more info on
this, check out our article on `}<a parentName="p" {...{
          "href": "/guidelines/globalization/rtl-and-bidi"
        }}>{`RTL and Bidirectionality`}</a>{`.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/89792fc6dd2b456fd3fb4504bfc530e2/07a9c/system_icon_bidirectional.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "32.16216216216216%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of bidirectional System Icons.",
              "title": "Example of bidirectional System Icons.",
              "src": "/static/89792fc6dd2b456fd3fb4504bfc530e2/50383/system_icon_bidirectional.png",
              "srcSet": ["/static/89792fc6dd2b456fd3fb4504bfc530e2/1efb2/system_icon_bidirectional.png 370w", "/static/89792fc6dd2b456fd3fb4504bfc530e2/50383/system_icon_bidirectional.png 740w", "/static/89792fc6dd2b456fd3fb4504bfc530e2/07a9c/system_icon_bidirectional.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "cultural-context"
      }}>{`Cultural Context`}</h2>
      <p>{`It is important to be aware how people from other cultures may view your chosen icon. Users around
the globe may have different interpretations of what the icon represents and the action or message
the icon is trying to convey. A misinterpretation can affect someone's ability to understand the
icon’s meaning, or in certain instances, it may be viewed as offensive.`}</p>
      <p>{`Users have several controls related to how things will be formatted and displayed within Workday:`}</p>
      <ul>
        <li parentName="ul">{`Language: Controls the language itself`}</li>
        <li parentName="ul">{`Locale: Controls the locale setting, like date and currency formats`}</li>
        <li parentName="ul">{`Currency: Displays the users preferred currency`}</li>
      </ul>
      <p>{`It is important to keep these controls in mind when selecting icons that contain text, currency,
date, and time. Workday aims to deliver a culturally and linguistically inclusive user experience to
our 20+ million customers in over 160 countries, with more than 75% of people speaking a non-English
language.`}</p>
      <h3 {...{
        "id": "web-examples"
      }}>{`Web Examples`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Icon`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Cultural Context`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><span parentName="td" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "75px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/b023220bd5d65cfedcd8d5b8b53e3220/1db73/dollar-image.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3klEQVR42q2U6S8DURTF++f6YAtFiy46umirtbSWWIOQ2IIIRWoptYudWEIIQcrUUi3ameO9RwnpaIubzJc3c39z7rn3XRkSCEEQ2JNIyPDPERcoiiI8s8sYnfAiFHp6O/sLMEJK5UqrINeUwH979/GTpIDRBAoYGZ9BkcWJQkMZht0ePAQCP0JlUrDj0zNozQ6kKTlkFRohV5cgI18Pg70GF1c+SagsFox21F7TjCyVETqrE139LnT0DkJFVKbINbA6Gz5zEgGeX1yhQG9HtsoEFykzGpPeRdiqmzA26cXz80tMlTGBl6Sk/GIbFLpSVNa1YvfgCLz/DqIg/n5sGtt7kK4sRq7WwjqsMVXAUd+GpbXN5Loc/ThIZq6zbxhKzsa8pPBstQmZBXoMjU0l1pQv8xeJ4DEYgu+ax9LqJroGXOCsVcwK6q/vho/vofDu0cLKOhtmi6MBgcfgx3u3Z46pVOisODw6+ZIjAXxbABvbe0jN45h/1U0d2NjZx9buPvMwr8gCtbEcvMStkfSwe2AEaQoOGcQ7ucaMHK2Z+GdgZy73dPIeUrXehRU2NmrSYXr1KmpbML+8/uN2iLscwuEw9OS60VJ5/+3vlsN3pXQmqdL7h8Dfgf++YJMFvgJB29DACphirAAAAABJRU5ErkJggg==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "dollar-example",
                    "title": "dollar-example",
                    "src": "/static/b023220bd5d65cfedcd8d5b8b53e3220/1db73/dollar-image.png",
                    "srcSet": ["/static/b023220bd5d65cfedcd8d5b8b53e3220/1db73/dollar-image.png 75w"],
                    "sizes": "(max-width: 75px) 100vw, 75px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Not everyone uses Dollars as currency. For example, some Workday customers use Euros.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><span parentName="td" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "75px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/e7b745c3e8491c893f61ef0af3aee4e4/1db73/hashmark-image.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZUlEQVR42q1U+0+SYRTmj2211ZoSCpoY4oXkpm5G5KJQiyFKiOIlrOZWbbXlD5SkWGaCcr9omKZyeXrPC9+nhBCuvu3d9533PXvO8z7nOZ8E//mRNJpYLBYb2pP8Hag22KUAheTgThi6YQtW17/wuFAo8LfN6cG4w418Ob4QkEAK5ZXLlxKfeby40qzEis8v5oVjcUiVGgzct4r5AgFJPXbZg59QaoZgND1GLpcX9z3eZVxvVcPnX69gLQIKiYGvWxifdMMxPY+p2ed4+GQSrSot9PcsPLa75uBwL6DbYEJ7j5HlzmCC5brmlvDr+OQMUKiwtPwW12QqyO5o+VKo9WjvNULepUdzhwa3OvtZAR0Ha+s2sJx+NN3ug4J97+1nq698eHSEbdaEeDKNdx98HOSRzYkYi0PhKMLROIZGxiBnhT6tbSCaSCK4G0E0nqzWULBBoXwwvfACN+Rq+MvdpSeeTHGGZqu9MR8KCT+yB+joG8SgeZTJcdbBmcVXvMjHzwGxGX/6kQOenJ5iJxJj9KNIpDLwMi1vtvXAyRpBMZ19D+2i12iGSjuMza0gtw7tU/erGPr8AW4DEr2FdVUQnb6lyrt8KdQGvi/v0vE82qOi37ZDJbbnNYzEEng6NcsZPRi1c6ABs5XHZJUJ1zxnRjLQhJB1yC50ls7sV8glaihUIE+RTmsbm+I1Iqy7LcxGI2OOms2ouDIJT08ilYaMXUfHjJzL58U5JTZXpZ3wrZYmg874yPGGXQAoVExl9nhn37xfqRipxZevYbLYcFyehjoEq2e51m+p3jXr+vBfwC71x24U8DeOeLD3gABz8QAAAABJRU5ErkJggg==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "hash-example",
                    "title": "hash-example",
                    "src": "/static/e7b745c3e8491c893f61ef0af3aee4e4/1db73/hashmark-image.png",
                    "srcSet": ["/static/e7b745c3e8491c893f61ef0af3aee4e4/1db73/hashmark-image.png 75w"],
                    "sizes": "(max-width: 75px) 100vw, 75px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Hash mark does not mean “number” in some countries. Instead, it is recognized in the context of social media only.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><span parentName="td" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "75px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/bb978a1be7c572eeacca7dfaee8a4918/1db73/graduation-image.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEUlEQVR42uVU/U9SYRTmzy0/8gtLk0AyvjRHoq1mlGyxXEVZ0lAUpRBURKBZ+e2VQL4kRGrDNnbv0/seuqlDHW3+0NbZzu57tnfPeZ7znPcqcMmh+A8BJalyOCgU8TWRwl4qU3MmkmmkMvsQZRCZYblcxt37T1B/XftX2XjjNlpvGbG5EyMwUZQqgBvbu2ju0sFqfwmvP4gprx/T7wPn5hTLGd8CnO5ZtKmNeOZwVgCl34DPx1y41nmHvqHICvzBMAJLkQtzMRSl5hqTBWqjBYXDYkVy8fsPdOnMjLqBurWoDGhgUi6S2nRTR/caO3qg0puZOj2xJsDS0U98WduCEEvANjpGoIFgBNtCHGsbO1jfFP4kr4XdBB7bHVBqTJhfiqJ30Apt3xDie6ljU0RRpGJ8YpZmmUxnqZZOuHfynmN8khhyh80PbTBZHlW7zOONy0P0+UrUAphMZ2B+YINhYJgcrmL4yukmc0ZGX8PzYR6RlVWSsp/L0yZwie/cXhgtwySZ76EMKDdXyPvDY25hGRrmmFJtwlVlN660aWjwLSo96pgZvOaGdfb0Y8hqRyj6iZlyj2ohFj/76ZVKR8gyRutbAvyLYWL99MVbTM74EP74GbF4EvnCId3lNWfKjVxm5ypAqcb3Ko+IM6xv16KuvftswFpDnlc29w2uaR8mPD7k8gf/4O+LM+XyeZ5y+TIZ/gKebJcsS4d64wAAAABJRU5ErkJggg==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "academic-example",
                    "title": "academic-example",
                    "src": "/static/bb978a1be7c572eeacca7dfaee8a4918/1db73/graduation-image.png",
                    "srcSet": ["/static/bb978a1be7c572eeacca7dfaee8a4918/1db73/graduation-image.png 75w"],
                    "sizes": "(max-width: 75px) 100vw, 75px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Not all countries use graduation caps. Consider other customs when designing for students globally.`}</td>
          </tr>
        </tbody>
      </table>
      <h2 {...{
        "id": "functionality"
      }}>{`Functionality`}</h2>
      <p>{`Icons are can be used to add an additional layer of context and recognizability to actions. In the
sections below you will find guidance for using system icons in this way.`}</p>
      <h3 {...{
        "id": "using-labels"
      }}>{`Using Labels`}</h3>
      <p>{`Icons must provide an equivalent text alternative for users who cannot perceive them, unless their
meaning can be derived elsewhere in text. Icons that do not communicate anything about the UI, or
have adjacent labels in text, may be considered decorative or redundant. Placing them in close
proximity to an associated task can be beneficial for those with low or partial vision. If an icon
cannot be labeled, a `}<a parentName="p" {...{
          "href": "#using-tooltips"
        }}>{`Tooltip`}</a>{` can be used to display an icon's name or
functionality, read more about that below.`}</p>
      <SideBySide mdxType="SideBySide">
        <Suggestion guidance="Label icons to display its name or function." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "548px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/ae0edca3f01fa60a110df8d4defe0bac/a58fe/system-labels-do.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "30.54054054054054%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating how to display icon name or function.",
                  "title": "Image demonstrating how to display icon name or function.",
                  "src": "/static/ae0edca3f01fa60a110df8d4defe0bac/a58fe/system-labels-do.png",
                  "srcSet": ["/static/ae0edca3f01fa60a110df8d4defe0bac/1efb2/system-labels-do.png 370w", "/static/ae0edca3f01fa60a110df8d4defe0bac/a58fe/system-labels-do.png 548w"],
                  "sizes": "(max-width: 548px) 100vw, 548px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="alert" guidance="When an icon cannot be labeled, use a tooltip." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "548px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/bcfea781ce7b400b05f13e2aa003b8d3/a58fe/system-labels-caution.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "30.54054054054054%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating how to use tooltips to label icons.",
                  "title": "Image demonstrating how to use tooltips to label icons.",
                  "src": "/static/bcfea781ce7b400b05f13e2aa003b8d3/a58fe/system-labels-caution.png",
                  "srcSet": ["/static/bcfea781ce7b400b05f13e2aa003b8d3/1efb2/system-labels-caution.png 370w", "/static/bcfea781ce7b400b05f13e2aa003b8d3/a58fe/system-labels-caution.png 548w"],
                  "sizes": "(max-width: 548px) 100vw, 548px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <h3 {...{
        "id": "using-tooltips"
      }}>{`Using Tooltips`}</h3>
      <p><a parentName="p" {...{
          "href": "/components/popups/tooltip"
        }}>{`Tooltips`}</a>{` are used to provide additional information or context. They
should never contain information that is vital to completing a task, as this is hidden from plain
sight when tooltips are used.`}</p>
      <p>{`Tooltips can also be used to display an icon's action (usually implemented with an
`}<a parentName="p" {...{
          "href": "/components/buttons/icon-button"
        }}>{`Icon Button`}</a>{`). It is important to be aware that tooltips are not
easily accessible on mobile devices, so labels are preferred where possible.`}</p>
      <SideBySide mdxType="SideBySide">
        <Suggestion guidance="Use tooltips to provide additional information that is non-essential for completing a task." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "292px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/c4fdc4e9a382ebf780b47beb045dd7c9/2e9f9/system-tooltip-do.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "28.08219178082192%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating how to use tooltips to provide additional information for icons.",
                  "title": "Image demonstrating how to use tooltips to provide additional information for icons.",
                  "src": "/static/c4fdc4e9a382ebf780b47beb045dd7c9/2e9f9/system-tooltip-do.png",
                  "srcSet": ["/static/c4fdc4e9a382ebf780b47beb045dd7c9/2e9f9/system-tooltip-do.png 292w"],
                  "sizes": "(max-width: 292px) 100vw, 292px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="error" guidance="Hiding essential information behind a tooltip makes it harder to discover." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "287px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/d58bc379c2a8dcba3770603f2a1a3cc0/480fd/system-tooltip-dont.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "28.57142857142857%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image displaying the use of tooltip that hides key information.",
                  "title": "Image displaying the use of tooltip that hides key information.",
                  "src": "/static/d58bc379c2a8dcba3770603f2a1a3cc0/480fd/system-tooltip-dont.png",
                  "srcSet": ["/static/d58bc379c2a8dcba3770603f2a1a3cc0/480fd/system-tooltip-dont.png 287w"],
                  "sizes": "(max-width: 287px) 100vw, 287px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <h3 {...{
        "id": "applying-touch-targets"
      }}>{`Applying Touch Targets`}</h3>
      <p>{`A minimum touch target of 48px is required around a system icon to achieve a usable touch target.
This is handled automatically when you use an `}<a parentName="p" {...{
          "href": "/components/buttons/icon-button"
        }}>{`Icon Button`}</a>{`.
Smaller touch targets make interacting with an icon difficult, especially when interacted with by an
individual who may be on the move or with declining mobility.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8490b787446e6ca0805c74e4a5050c27/07a9c/system_icon_touch_targets.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "26.756756756756754%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of touch target clearance used with System Icons.",
              "title": "Example of touch target clearance used with System Icons.",
              "src": "/static/8490b787446e6ca0805c74e4a5050c27/50383/system_icon_touch_targets.png",
              "srcSet": ["/static/8490b787446e6ca0805c74e4a5050c27/1efb2/system_icon_touch_targets.png 370w", "/static/8490b787446e6ca0805c74e4a5050c27/50383/system_icon_touch_targets.png 740w", "/static/8490b787446e6ca0805c74e4a5050c27/07a9c/system_icon_touch_targets.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "alignment"
      }}>{`Alignment`}</h2>
      <p>{`System icons should be center aligned vertically or horizontally depending on how they are stacked.
This ensures that icons look visually balanced when in close proximity to another icon. The same
rule should be followed when text accompanies an icon`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5753105fd7e09ed28c7c1d29d24c365a/07a9c/system_icon_alignment.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "27.56756756756757%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of icon alignment.",
              "title": "Example of icon alignment.",
              "src": "/static/5753105fd7e09ed28c7c1d29d24c365a/50383/system_icon_alignment.png",
              "srcSet": ["/static/5753105fd7e09ed28c7c1d29d24c365a/1efb2/system_icon_alignment.png 370w", "/static/5753105fd7e09ed28c7c1d29d24c365a/50383/system_icon_alignment.png 740w", "/static/5753105fd7e09ed28c7c1d29d24c365a/07a9c/system_icon_alignment.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "sizing"
      }}>{`Sizing`}</h2>
      <p>{`System icons are made to a specific standard. Changing the size of an icon will greatly diminish its
quality. Canvas does not recommend scaling icons. If for a specific reason you do need to scale an
icon we recommend following Canvas spacing rules resizing in increments of 4 pixels, never go below
20px.`}</p>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="alert" guidance="Use a standard size icon whenever possible and use Canvas spacing rules to scale an icon if needed." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "187px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/f1ee17c91c2a7adb457efbae1076a1a7/47fe2/system-sizing-caution.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "33.68983957219251%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating scaling icons using Canvas spacing rules",
                  "title": "Image demonstrating scaling icons using Canvas spacing rules",
                  "src": "/static/f1ee17c91c2a7adb457efbae1076a1a7/47fe2/system-sizing-caution.png",
                  "srcSet": ["/static/f1ee17c91c2a7adb457efbae1076a1a7/47fe2/system-sizing-caution.png 187w"],
                  "sizes": "(max-width: 187px) 100vw, 187px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="error" guidance="Use inconsistant sizes, always refer to Canvas spacing rules when scaling assets." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "187px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/60bf2fa451492ca9a79d49e868bc767c/47fe2/system-sizing-dont.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "33.68983957219251%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating scaling icons with inconsistent sizes",
                  "title": "Image demonstrating scaling icons with inconsistent sizes",
                  "src": "/static/60bf2fa451492ca9a79d49e868bc767c/47fe2/system-sizing-dont.png",
                  "srcSet": ["/static/60bf2fa451492ca9a79d49e868bc767c/47fe2/system-sizing-dont.png 187w"],
                  "sizes": "(max-width: 187px) 100vw, 187px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <h2 {...{
        "id": "color"
      }}>{`Color`}</h2>
      <p>{`A variety of system icons can be modified to highlight specific details that you wish to call
attention to, there are 3 interactive layers where the color can be changed at an individual level.
For more information on this check out the `}<a parentName="p" {...{
          "href": "#tab=create-your-own&heading=layers"
        }}>{`Layers section`}</a>{` in
the "Create Your Own" tab.`}</p>
      <h2 {...{
        "id": "web-examples-1"
      }}>{`Web Examples`}</h2>
      <p>{`The icons shipped in `}<inlineCode parentName="p">{`@workday/canvas-system-icons-web`}</inlineCode>{` are just SVGs with some additional metadata.
If you'd like to easily use them in React, use the `}<inlineCode parentName="p">{`SystemIcon`}</inlineCode>{` component from
`}<inlineCode parentName="p">{`@workday/canvas-kit-react/icon`}</inlineCode>{`:`}</p>
      <ExampleCodeBlock code={Example} scope={{
        activityStreamIcon
      }} showCode={true} mdxType="ExampleCodeBlock" />
      <SymbolDoc name="SystemIcon" hideHeading headingStart={2} mdxType="SymbolDoc" />
      <h3 {...{
        "id": "systemiconcircle"
      }}>{`SystemIconCircle`}</h3>
      <p>{`This is another component provided to quickly render a system icon with a colored background of your
choice.`}</p>
      <ExampleCodeBlock code={Example2} scope={{
        activityStreamIcon
      }} showCode={true} mdxType="ExampleCodeBlock" />
      <SymbolDoc name="SystemIconCircle" hideHeading headingStart={3} mdxType="SymbolDoc" />
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "ios-examples"
        }}>{`iOS Examples`}</h2>
        <p>{`Coming soon!`}</p>
        <h2 {...{
          "id": "android-examples"
        }}>{`Android Examples`}</h2>
        <p>{`Coming soon!`}</p>
      </InternalContent>
    </TabPanel>
    <TabPanel data-id="Gallery" mdxType="TabPanel">
      <SearchableIconList iconType="wd-system-icons" mdxType="SearchableIconList" />
    </TabPanel>
    <TabPanel data-id="Create Your Own" mdxType="TabPanel">
      <h2 {...{
        "id": "create-your-own"
      }}>{`Create Your Own`}</h2>
      <p>{`Can’t find the icon you need in our library? Why not make your own! Follow the steps outlined here
to create your own icon to contribute back to the Canvas Design System. Before creating a new icon,
make sure you have checked over the `}<a parentName="p" {...{
          "href": "#tab=gallery"
        }}>{`Gallery`}</a>{` and searched for keywords to surface an
icon that may already exist.`}</p>
      <InternalContent mdxType="InternalContent">
        <p><a parentName="p" {...{
            "href": "https://www.figma.com/file/na2nUV6wzre6uZoYQN6XmD/System-Icon-Contribution-Workspace"
          }}>{`Follow this link`}</a>{`
to access the guided System Icon Workspace file in Figma.`}</p>
      </InternalContent>
      <h2 {...{
        "id": "base-grid"
      }}>{`Base Grid`}</h2>
      <p>{`System icons are built on a base grid, using a grid will help you draw your icon to Workdays brand
specific parameters, keeping things consistent. Canvas maintains a base grid of 24x24 px for system
icons.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "720px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e012e6561c10d93107ce97d55f661e1b/37523/system_icon_base_grid.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Base grid illustration for System Icons.",
              "title": "Base grid illustration for System Icons.",
              "src": "/static/e012e6561c10d93107ce97d55f661e1b/37523/system_icon_base_grid.png",
              "srcSet": ["/static/e012e6561c10d93107ce97d55f661e1b/1efb2/system_icon_base_grid.png 370w", "/static/e012e6561c10d93107ce97d55f661e1b/37523/system_icon_base_grid.png 720w"],
              "sizes": "(max-width: 720px) 100vw, 720px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "safe-area"
      }}>{`Safe Area`}</h3>
      <p>{`The safe area ensures that the system icon remains fully visible should the icon be scaled up or
down. No part of the icon should extend to the surrounding white space of the safe area.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "720px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/497a2bc11451142faf19860ce9ea6055/37523/system_icon_safe_area.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Safe Area illustration for System Icons.",
              "title": "Safe Area illustration for System Icons.",
              "src": "/static/497a2bc11451142faf19860ce9ea6055/37523/system_icon_safe_area.png",
              "srcSet": ["/static/497a2bc11451142faf19860ce9ea6055/1efb2/system_icon_safe_area.png 370w", "/static/497a2bc11451142faf19860ce9ea6055/37523/system_icon_safe_area.png 720w"],
              "sizes": "(max-width: 720px) 100vw, 720px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "key-shapes"
      }}>{`Key Shapes`}</h3>
      <p>{`Using key shapes provides a consistent size and proportions across the entire system icon suite.
They help maintain an icons optical alignment and visual style when in close proximity to other
icons.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/bb6c1d3bce0e578408b4ba7b37bd1aef/07a9c/system_icon_key_shapes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Key Shapes illustration for System Icons.",
              "title": "Key Shapes illustration for System Icons.",
              "src": "/static/bb6c1d3bce0e578408b4ba7b37bd1aef/50383/system_icon_key_shapes.png",
              "srcSet": ["/static/bb6c1d3bce0e578408b4ba7b37bd1aef/1efb2/system_icon_key_shapes.png 370w", "/static/bb6c1d3bce0e578408b4ba7b37bd1aef/50383/system_icon_key_shapes.png 740w", "/static/bb6c1d3bce0e578408b4ba7b37bd1aef/07a9c/system_icon_key_shapes.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><strong parentName="p">{`All Key Shapes`}</strong></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/346433bb1d4233119024cb6459adeddb/07a9c/system_icon_all_key_shapes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "27.837837837837835%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "All Key Shapes using System Icons to demonstate consistancy.",
              "title": "All Key Shapes using System Icons to demonstate consistancy.",
              "src": "/static/346433bb1d4233119024cb6459adeddb/50383/system_icon_all_key_shapes.png",
              "srcSet": ["/static/346433bb1d4233119024cb6459adeddb/1efb2/system_icon_all_key_shapes.png 370w", "/static/346433bb1d4233119024cb6459adeddb/50383/system_icon_all_key_shapes.png 740w", "/static/346433bb1d4233119024cb6459adeddb/07a9c/system_icon_all_key_shapes.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "strokes"
      }}>{`Strokes`}</h2>
      <p>{`Maintain a 2px inner stroke on all shapes used to build an icon. This includes the use of circles.
An inner stroke allows the icon to remain consistent when a corner radius is applied later on.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/37a5c768c0446b51ec7c6481cb94fb3c/07a9c/system_icon_strokes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "2px stroke illustration for System Icons.",
              "title": "2px stroke illustration for System Icons.",
              "src": "/static/37a5c768c0446b51ec7c6481cb94fb3c/50383/system_icon_strokes.png",
              "srcSet": ["/static/37a5c768c0446b51ec7c6481cb94fb3c/1efb2/system_icon_strokes.png 370w", "/static/37a5c768c0446b51ec7c6481cb94fb3c/50383/system_icon_strokes.png 740w", "/static/37a5c768c0446b51ec7c6481cb94fb3c/07a9c/system_icon_strokes.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "corners"
      }}>{`Corners`}</h2>
      <p>{`A corner radius of 0.5px should be applied to a shape, creating a sharp interior in the process.
Maintaining a consistent corner radius to ensure visual consistency.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3858a5d096194604afbb152f4dbc2083/07a9c/system_icon_corners.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.189189189189186%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Corner radius illustration for System Icons.",
              "title": "Corner radius illustration for System Icons.",
              "src": "/static/3858a5d096194604afbb152f4dbc2083/50383/system_icon_corners.png",
              "srcSet": ["/static/3858a5d096194604afbb152f4dbc2083/1efb2/system_icon_corners.png 370w", "/static/3858a5d096194604afbb152f4dbc2083/50383/system_icon_corners.png 740w", "/static/3858a5d096194604afbb152f4dbc2083/07a9c/system_icon_corners.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "angles"
      }}>{`Angles`}</h2>
      <p>{`Use 45° angles for even anti-aliasing on icons, maintain 15° steps when more extreme angles are
required.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ec0cbd4cf894cec3bbbc2f6e3ccee12e/07a9c/system_icon_angles.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.189189189189186%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Angles of degrees illustration for System Icons.",
              "title": "Angles of degrees illustration for System Icons.",
              "src": "/static/ec0cbd4cf894cec3bbbc2f6e3ccee12e/50383/system_icon_angles.png",
              "srcSet": ["/static/ec0cbd4cf894cec3bbbc2f6e3ccee12e/1efb2/system_icon_angles.png 370w", "/static/ec0cbd4cf894cec3bbbc2f6e3ccee12e/50383/system_icon_angles.png 740w", "/static/ec0cbd4cf894cec3bbbc2f6e3ccee12e/07a9c/system_icon_angles.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "density"
      }}>{`Density`}</h2>
      <p>{`Some icons require a compact use of the base grid, Canvas advise a minimum of 1px between each shape
to ensure the icons can be accurately depicted on screen, anything less may diminish the icons
appearance.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0a343acc161bace56c988bb05432df28/07a9c/system_icon_density.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "49.45945945945946%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Density of pixels illustration for System Icons.",
              "title": "Density of pixels illustration for System Icons.",
              "src": "/static/0a343acc161bace56c988bb05432df28/50383/system_icon_density.png",
              "srcSet": ["/static/0a343acc161bace56c988bb05432df28/1efb2/system_icon_density.png 370w", "/static/0a343acc161bace56c988bb05432df28/50383/system_icon_density.png 740w", "/static/0a343acc161bace56c988bb05432df28/07a9c/system_icon_density.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "layers"
      }}>{`Layers`}</h2>
      <p>{`There are 3 layer options available for system icons, this allows parts of the icon to be modified
or hidden in code as needed. By default all layers are set to Licorice 200 and background layers are
hidden, these layers can be targeted in code to create richer icons.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0a52e4b7bde567c4cd14a137474a2418/07a9c/system_icon_layers.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.189189189189186%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Layer usage illustration for System Icons.",
              "title": "Layer usage illustration for System Icons.",
              "src": "/static/0a52e4b7bde567c4cd14a137474a2418/50383/system_icon_layers.png",
              "srcSet": ["/static/0a52e4b7bde567c4cd14a137474a2418/1efb2/system_icon_layers.png 370w", "/static/0a52e4b7bde567c4cd14a137474a2418/50383/system_icon_layers.png 740w", "/static/0a52e4b7bde567c4cd14a137474a2418/07a9c/system_icon_layers.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "naming"
      }}>{`Naming`}</h2>
      <p>{`Generic names make icons more discoverable and scalable, catering for more use cases. When naming an
icon try to pick a generic name rather than something specific to your immediate need or unique to
only your product area.`}</p>
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "contribution"
        }}>{`Contribution`}</h2>
        <p>{`Once you're ready to submit your icon, go check out the information supplied in the Contributing
Back To Canvas section of this site to help you make your submission.`}</p>
        <p>{`Thank you for making a contribution!`}</p>
      </InternalContent>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      